<template>
  <g id="Groupe_516" data-name="Groupe 516" transform="translate(13404 -10580)">
    <g id="Groupe_20" data-name="Groupe 20" transform="translate(-13663.966 9944)">
      <path id="Tracé_17" data-name="Tracé 17" d="M46.919,46.443a3.623,3.623,0,1,0-5.118-.15,3.623,3.623,0,0,0,5.118.15" transform="translate(247.531 624.67)"/>
      <path id="Tracé_16" data-name="Tracé 16" d="M42.943,54.463c2.058-1.694,4.1-3.538,6.1-5.508,2.28-2.238,4.383-4.527,6.3-6.826C60.891,50.1,63.158,56.865,60.57,59.5a4.354,4.354,0,0,1-3.224,1.121c-3.43,0-8.6-2.242-14.4-6.158M13.364,59.541c-2.594-2.639-.312-9.428,5.252-17.416,1.911,2.285,4,4.565,6.269,6.789,2.024,1.99,4.086,3.844,6.157,5.556-5.827,3.937-11.013,6.188-14.457,6.192a4.344,4.344,0,0,1-3.22-1.121m16.262-16.1c-2.4-2.357-4.605-4.737-6.586-7.084,2.065-2.476,4.381-4.993,6.911-7.48,2.344-2.305,4.711-4.421,7.041-6.327,2.313,1.9,4.66,4,6.989,6.287,2.544,2.5,4.871,5.03,6.946,7.521-1.99,2.359-4.208,4.754-6.62,7.125-2.435,2.4-4.9,4.592-7.316,6.558-2.439-1.974-4.918-4.188-7.366-6.6M49.372,24c-2.146-2.109-4.337-4.061-6.531-5.858,8.324-5.7,15.406-8.029,18.058-5.329,2.625,2.673.248,9.6-5.468,17.719-1.858-2.2-3.879-4.391-6.059-6.534M13.035,12.772c2.656-2.7,9.763-.36,18.109,5.363-2.217,1.811-4.419,3.774-6.583,5.9s-4.181,4.316-6.032,6.5C12.794,22.4,10.4,15.45,13.035,12.777m-5.288-5.2c-5.01,5.1-2.089,16.574,6.35,28.668C5.4,48.506,2.349,60.214,7.423,65.379c5.115,5.207,17.04,2.255,29.568-6.4C49.5,67.605,61.4,70.541,66.509,65.341c5.067-5.159,2.028-16.846-6.65-29.093,8.423-12.077,11.33-23.537,6.324-28.628-5.062-5.154-16.8-2.319-29.191,6.135C28.794,8.148,20.873,5,14.981,5A9.789,9.789,0,0,0,7.747,7.578" transform="translate(255 632)"/>
    </g>
  </g>
</template>

<script>
export default {
  name: "IconPlaneteOnline"
  // todo : Revoir les path de cet icône et les rassembler en un seul
}
</script>
